export const callStatusMessages = [
    { key: "ANSWERED", value: "The call was successfully connected." },
    { key: "BUSY", value: "The recipient's line was busy." },
    { key: "BUYER_ANSWERED", value: "The call was successfully connected and answered." },
    { key: "BUYER_INVALID", value: "The buyer (recipient) is invalid or does not exist." },
    { key: "BUYER_NOT_FOUND", value: "The system could not locate the buyer (recipient)." },
    { key: "CALLERID_BLOCKED_BY_USER", value: "The recipient has blocked this caller ID." },
    { key: "CAMPAIGN_LIMIT_REACHED", value: "The campaign has reached its call limit." },
    { key: "CANCEL", value: "The call was canceled before it was answered." },
    { key: "CHANUNAVAIL", value: "The requested channel (trunk or extension) is unavailable." },
    { key: "CONGESTION", value: "The call could not be completed due to network congestion." },
    { key: "DID_CAMPAIGN_MISSING", value: "The dialed DID is not linked to any active campaign." },
    { key: "DID_NOT_ACTIVE", value: "The dialed DID number is inactive." },
    { key: "DID_USER_NOT_ACTIVE", value: "The user associated with the DID is inactive." },
    { key: "FORWARD_NUMBER_NOT_FOUND", value: "The call was supposed to be forwarded but failed." },
    { key: "IVR_AUTHENTICATION_FAILED", value: "The caller failed the IVR authentication." },
    { key: "NOANSWER", value: "The call rang but was not answered." },
    { key: "NOT_SUFFICIENT_FUNDS", value: "The caller's account does not have enough balance." },
    { key: "USER_NOT_FOUND", value: "The user does not exist." },
];