export const GET_ADMIN_PROMOTION_REQUEST = "GET_ADMIN_PROMOTION_REQUEST";
export const GET_ADMIN_PROMOTION_SUCCESS = "GET_ADMIN_PROMOTION_SUCCESS";
export const GET_ADMIN_PROMOTION_FAIL = "GET_ADMIN_PROMOTION_FAIL";

export const CREATE_ADMIN_PROMOTION_REQUEST = "CREATE_ADMIN_PROMOTION_REQUEST";
export const CREATE_ADMIN_PROMOTION_SUCCESS = "CREATE_ADMIN_PROMOTION_SUCCESS";
export const CREATE_ADMIN_PROMOTION_FAIL = "CREATE_ADMIN_PROMOTION_FAIL";

export const UPDATE_ADMIN_PROMOTION_REQUEST = "UPDATE_ADMIN_PROMOTION_REQUEST";
export const UPDATE_ADMIN_PROMOTION_SUCCESS = "UPDATE_ADMIN_PROMOTION_SUCCESS";
export const UPDATE_ADMIN_PROMOTION_FAIL = "UPDATE_ADMIN_PROMOTION_FAIL";

export const DELETE_ADMIN_PROMOTION_REQUEST = "DELETE_ADMIN_PROMOTION_REQUEST";
export const DELETE_ADMIN_PROMOTION_SUCCESS = "DELETE_ADMIN_PROMOTION_SUCCESS";
export const DELETE_ADMIN_PROMOTION_FAIL = "DELETE_ADMIN_PROMOTION_FAIL";